import { Injectable, OnDestroy, inject } from '@angular/core';
import { APP_CONFIG } from '../common/app-config';
import {
  HubConnection,
  HubConnectionBuilder,
  IHttpConnectionOptions,
  LogLevel
} from '@microsoft/signalr';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { AuthService } from './auth.service';
import { useCypressSignalRMock } from 'cypress-signalr-mock';
import { environment } from '../../environments/environment';

export interface ISignalrService {
  reconnected: Subject<void>;
  connect(): Promise<void>;
  register<TResult>(methodName: string): Subject<TResult>;
}

export enum ConnectionStatus {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED'
}

@Injectable({
  providedIn: 'root'
})
export class SignalrService implements ISignalrService, OnDestroy {
  private appConfig = inject(APP_CONFIG);
  private authService = inject(AuthService);

  private options: IHttpConnectionOptions;
  private connection: HubConnection | null = null;
  public reconnected: Subject<void> = new Subject<void>();
  private subs: Subscription = new Subscription();
  private status: ConnectionStatus = ConnectionStatus.DISCONNECTED;
  status$: Subject<ConnectionStatus> = new BehaviorSubject<ConnectionStatus>(
    ConnectionStatus.DISCONNECTED
  );

  public constructor() {
    const authService = this.authService;
    this.options = {
      accessTokenFactory(): string | Promise<string> {
        return new Promise<string>((resolve, reject) => {
          const token = authService.token.getValue();
          if (!token) {
            reject(new Error('No token available'));
            return;
          }

          if (!authService.isLoggedIn()) {
            const sub = authService.refreshToken().subscribe({
              next: (token) => {
                resolve(token.token);
                sub.unsubscribe();
              },
              error: (error) => {
                reject(error);
              }
            });
          } else resolve(token);
        });
      }
    };

    const tokenSubscription = authService.token.subscribe({
      next: async (token) => {
        if (this.connection || !token) return;
        await this.connect();
      }
    });

    this.subs.add(tokenSubscription);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.connection?.stop();
  }

  async connect() {
    if (!this.authService.isLoggedIn()) {
      return;
    }

    this.connection =
      useCypressSignalRMock('data') ??
      new HubConnectionBuilder()
        .configureLogging(
          environment.production ? LogLevel.Error : LogLevel.Debug
        )
        .withUrl(this.appConfig.apiUrl + '/data', this.options)
        .withAutomaticReconnect()
        .build();

    this.connection.onreconnected(() => {
      this.reconnected.next();
      this.status$.next(ConnectionStatus.CONNECTED);
      this.connectionSuccessful();
    });

    this.connection.onreconnecting(() => {
      this.status = ConnectionStatus.DISCONNECTED;
      this.status$.next(this.status);
    });

    this.connection.onclose(() => {
      this.status = ConnectionStatus.DISCONNECTED;
      this.status$.next(this.status);
    });

    await this.connection
      .start()
      .then(() => {
        this.status = ConnectionStatus.CONNECTED;
        this.status$.next(this.status);
        this.connectionSuccessful();
      })
      .catch((error) => {
        this.status = ConnectionStatus.DISCONNECTED;
        this.status$.next(this.status);
        throw error;
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private subjects: Record<string, any> = {};

  connectionSuccessful() {
    if (!this.connection?.connectionId)
      throw new Error('Connection not established');

    for (const n in this.subjects) {
      if (this.subjects[n].isConnected) continue;

      this.connection.on(n, (data) => {
        this.subjects[n].subject.next(data);
      });
      this.subjects[n].isConnected = true;
    }
  }

  register<TResult>(methodName: string) {
    const subject = new Subject<TResult>();
    this.subjects[methodName] = { subject: subject, isConnected: false };

    if (this.connection && this.status === ConnectionStatus.CONNECTED) {
      this.connection.on(methodName, (data: TResult) => {
        subject.next(data);
      });
      this.subjects[methodName].isConnected = true;
    }

    return subject;
  }
}
